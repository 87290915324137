<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--a-col :md="5" :sm="24">
              <a-form-item label="房屋编号">
                <a-input placeholder="房屋编号" v-model="queryParam.id" allow-clear/>
              </a-form-item>
            </a-col-->
            <a-col :md="6" :sm="24">
              <a-form-item label="房屋号码">
                <a-input placeholder="请输入房屋号" v-model="queryParam.houseName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="住户绑定">
                <a-select
                  v-model="queryParam.isBinded"
                  placeholder="请选择"
                >
                  <a-select-option :key="3" :value="-1">请选择</a-select-option>
                  <a-select-option :key="1" :value="1">已绑定</a-select-option>
                  <a-select-option :key="0" :value="0">未绑定</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="区域">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="defaultRegion"
                  @change="changeRegion"
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="选择小区">
                  <a-select
                    v-model="queryParam.communityId"
                    placeholder="请选择小区"
                    @change="changeCommunity"
                  >
                    <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                    <a-select-option v-for="item in regionCommunity" :key="item.keyId" :value="item.id">
                      {{ item.communityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="选择楼宇">
                  <a-select
                    v-model="queryParam.buildingId"
                    placeholder="选择楼宇"
                    @change="changebuilding"
                  >
                    <a-select-option :key="0" :value="0">选择楼宇</a-select-option>
                    <a-select-option v-for="item in regionBuilding" :key="item.keyId" :value="item.id">
                      {{ item.buildingName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="选择单元">
                  <a-select v-model="queryParam.unitId" placeholder="选择单元">
                    <a-select-option :key="0" :value="0">选择单元</a-select-option>
                    <a-select-option v-for="item in regionUnit" :key="item.keyId" :value="item.id">
                      {{ item.unitName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="isBinded" slot-scope="text">
          <a-tag color="#2db7f5" v-if="text === 1">已绑定</a-tag>
          <a-tag color="cyan" v-else>未绑定</a-tag>
        </span>
        <div
          slot="expandedRowRender"
          slot-scope="record"
          style="margin: 0">
          <a-row
            :gutter="24"
          >
            <a-col :span="12" v-for="(residentRow, index) in record.resident" :key="index">
              <a-col :span="10">
                <span style="margin-right: 10px;">{{ residentRow.realName }} {{ residentRow.telephone }}</span>
                <span><a @click="editSpecial(residentRow)">编辑</a></span>
                <a-divider type="vertical" />
                <a-popconfirm title="确认要删除此住户吗？" @confirm="delResidentApi(residentRow.keyId, residentRow.roomId)">
                  <span><a>删除</a></span>
                </a-popconfirm>
              </a-col>
              <a-col :span="14" v-if="residentRow.special.length > 0">
                <a-tag color="cyan" v-for="(action, k) in residentRow.special" :key="k">{{ action.typeName }}</a-tag>
              </a-col>
              <a-col :span="14" v-else>-</a-col>
              <a-divider :dashed="true"></a-divider>
            </a-col>
            <a-col v-if="record.resident.length === 0">
              <a-col :span="24" style="text-align: center;">
                <span>暂无住户</span>
              </a-col>
            </a-col>
          </a-row>
        </div>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="gotoResident(record.id)">查看住户</a>
          <a-divider type="vertical" />
          <a @click="addSpecial(record)">添加住户</a>
        </span>
      </s-table>

      <a-modal
        title="添加/编辑房间"
        :width="800"
        :destroyOnClose="true"
        v-model="visible"
        @ok="saveUnitApi"
      >
        <a-form :form="form">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="区域"
            :validateStatus="checkParams.areaCodeStatus"
            :help="checkParams.areaCodeStatusMsg"
          >
            <a-cascader
              :allowClear="false"
              style="width:200px;"
              :options="regionList"
              placeholder="省-市-区(县)"
              :field-names="{label: 'label', value: 'value', children: 'children' }"
              @change="changeAddRegion"
              v-decorator="['areaCode', {initialValue: addDefaultRegion, rules: [{required: true}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="小区"
            :validateStatus="checkParams.communityIdStatus"
            :help="checkParams.communityIdStatusMsg"
          >
            <a-select
              style="width:200px;"
              placeholder="请选择小区"
              v-model="mdl.communityId"
              @change="changeAddCommunity"
            >
              <a-select-option :key="0" :value="0">请选择小区</a-select-option>
              <a-select-option v-for="item in regionCommunityAdd" :key="item.keyId" :value="item.id">
                {{ item.communityName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="楼宇"
            :validateStatus="checkParams.buildingIdStatus"
            :help="checkParams.buildingIdStatusMsg"
          >
            <a-select
              style="width:200px;"
              placeholder="请选择楼宇"
              v-model="mdl.buildingId"
              @change="changeAddBuilding"
            >
              <a-select-option :key="0" :value="0">请选择楼宇</a-select-option>
              <a-select-option v-for="item in regionBuildingAdd" :key="item.keyId" :value="item.id">
                {{ item.buildingName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="单元"
            :validateStatus="checkParams.unitIdStatus"
            :help="checkParams.unitIdStatusMsg"
          >
            <a-select
              style="width:200px;"
              placeholder="请选择单元"
              v-model="mdl.unitId"
              @change="changeAddUnit"
            >
              <a-select-option :key="0" :value="0">请选择单元</a-select-option>
              <a-select-option v-for="item in regionUnitAdd" :key="item.keyId" :value="item.id">
                {{ item.unitName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="房间号"
            :validateStatus="checkParams.houseNameStatus"
            :help="checkParams.houseNameStatusMsg"
          >
            <a-input
              placeholder="请输入房间号"
              v-decorator="['houseName', {initialValue: mdl.houseName, rules: [{required: true, validator: houseNameCheck, validateTrigger: 'change'}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="备注"
          >
            <a-textarea :rows="5" v-model="mdl.desc" placeholder="备注" />
          </a-form-item>
        </a-form>
      </a-modal>

      <a-modal
        title="添加/编辑住户"
        :width="800"
        :destroyOnClose="true"
        v-model="specialVisible"
        @ok="saveResidentApi"
      >
        <a-form :form="form">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="地址"
          >
            <a-input v-model="special.address" :disabled="true" />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="手机号"
            :validateStatus="checkParams.telephoneStatus"
            :help="checkParams.telephoneStatusMsg"
          >
            <a-input
              placeholder="请输入手机号"
              v-decorator="['telephone', {initialValue: special.telephone, rules: [{required: true, validator: telephoneCheck, validateTrigger: 'change'}]}]"
              :maxLength="11"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="用户姓名"
            :validateStatus="checkParams.realNameStatus"
            :help="checkParams.realNameStatusMsg"
          >
            <a-input
              placeholder="请输入用户姓名"
              v-decorator="['realName', {initialValue: special.realName, rules: [{required: true, validator: realNameCheck, validateTrigger: 'change'}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="邮箱"
          >
            <a-input
              placeholder="请输入邮箱"
              v-model="special.email"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="性别"
          >
            <a-radio-group
              v-model="special.gender"
              style="width: 100%"
            >
              <a-radio :value="0">未知</a-radio>
              <a-radio :value="1">男</a-radio>
              <a-radio :value="2">女</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="备注"
          >
            <a-textarea :rows="5" v-model="special.desc" placeholder="备注" />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="标签"
          >
            <a-col :md="6" :sm="6" v-for="(item, index) in specialList" :key="index">
              <a-checkbox
                :value="item.id"
                :checked="special.specialIdArr.indexOf(item.id) > -1 ? true : false"
                @change="specialTypeChange"
              >
                {{ item.typeName }}
              </a-checkbox>
            </a-col>
          </a-form-item>
        </a-form>
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import { regionCommunity } from '@/api/community'
import { regionList } from '@/api/region'
import { regionBuilding } from '@/api/building'
import { regionUnit } from '@/api/unit'
import { checkHouse, houseCondition, houseList, saveHouse } from '@/api/house'
import { checkTelephone, delResident, saveResident } from '@/api/resident'
import { specialList } from '@/api/special'
const columns = [
  /* {
    title: '房间编号',
    dataIndex: 'id'
  }, */
  {
    title: '房间号',
    dataIndex: 'houseName'
  },
  {
    title: '单元名称',
    dataIndex: 'unitName'
  },
  {
    title: '楼宇名称',
    dataIndex: 'buildingName'
  },
  {
    title: '小区名称',
    dataIndex: 'communityName'
  },
  {
    title: '区域',
    dataIndex: 'areaCodeName'
  },
  {
    title: '是否已绑定住户',
    dataIndex: 'isBinded',
    scopedSlots: { customRender: 'isBinded' }
  },
  {
    title: '添加时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '220px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'UnitList',
  components: {
    STable,
    Ellipsis,
    STree
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      specialVisible: false,
      form: this.$form.createForm(this, { name: 'addRoleFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        id: '',
        houseName: '',
        communityId: 0,
        buildingId: 0,
        unitId: 0,
        areaCode: 0,
        isBinded: -1,
        desc: ''
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return houseList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      checkParams: {
        houseNameStatus: 'success',
        houseNameStatusMsg: null,
        areaCodeStatus: 'success',
        areaCodeStatusMsg: null,
        communityIdStatus: 'success',
        communityIdStatusMsg: null,
        buildingIdStatus: 'success',
        buildingIdStatusMsg: null,
        unitIdStatus: 'success',
        unitIdStatusMsg: null,
        telephoneStatus: 'success',
        telephoneStatusMsg: null,
        realNameStatus: 'success',
        realNameStatusMsg: null
      },
      dataParams: {
        communityName: ''
      },
      regionList: [],
      defaultRegion: [],
      addDefaultRegion: [],
      regionCommunity: [],
      regionCommunityAdd: [],
      regionBuilding: [],
      regionBuildingAdd: [],
      houseList: [],
      regionUnit: [],
      regionUnitAdd: [],
      special: {
        specialIdArr: []
      },
      specialList: []
    }
  },
  filters: {
  },
  created () {
    this.regionListApi()
    if (this.$route.query.communityId !== 0 && this.$route.query.communityId !== undefined && this.$route.query.communityId !== '') {
      this.queryParam.communityId = this.$route.query.communityId
    }

    if (this.$route.query.buildingId !== 0 && this.$route.query.buildingId !== undefined && this.$route.query.buildingId !== '') {
      this.queryParam.buildingId = this.$route.query.buildingId
    }

    if (this.$route.query.unitId !== 0 && this.$route.query.unitId !== undefined && this.$route.query.unitId !== '') {
      this.queryParam.unitId = this.$route.query.unitId
    }

    if (this.queryParam.buildingId !== 0 || this.queryParam.communityId !== 0 || this.queryParam.unitId !== 0) {
      this.houseConditionApi(this.queryParam.communityId, this.queryParam.buildingId, this.queryParam.unitId)
    } else {
      this.queryParam.buildingId = 0
      this.queryParam.communityId = 0
      this.queryParam.unitId = 0
      this.queryParam.areaCode = 0
    }
    // 标签
    this.specialListApi()
  },
  watch: {
    $route (val) {
      if (val.name === 'houseManageList') {
        this.regionListApi()
        this.queryParam = {
          id: '',
          houseName: '',
          communityId: 0,
          buildingId: 0,
          unitId: 0,
          areaCode: 0,
          isBinded: -1,
          desc: ''
        }
        this.defaultRegion = []
        if (this.$route.query.communityId !== 0 && this.$route.query.communityId !== undefined && this.$route.query.communityId !== '') {
          this.queryParam.communityId = this.$route.query.communityId
        }

        if (this.$route.query.buildingId !== 0 && this.$route.query.buildingId !== undefined && this.$route.query.buildingId !== '') {
          this.queryParam.buildingId = this.$route.query.buildingId
        }

        if (this.$route.query.unitId !== 0 && this.$route.query.unitId !== undefined && this.$route.query.unitId !== '') {
          this.queryParam.unitId = this.$route.query.unitId
        }

        if (this.queryParam.buildingId !== 0 || this.queryParam.communityId !== 0 || this.queryParam.unitId !== 0) {
          this.houseConditionApi(this.queryParam.communityId, this.queryParam.buildingId, this.queryParam.unitId)
        } else {
          this.queryParam.buildingId = 0
          this.queryParam.communityId = 0
          this.queryParam.unitId = 0
          this.queryParam.areaCode = 0
          this.regionCommunity = []
          this.regionBuilding = []
          this.regionUnit = []
        }
        // 标签
        this.specialListApi()
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    specialListApi () {
      const _this = this
      specialList().then((res) => {
        if (res.errorCode === 0) {
          _this.specialList = res.result.data
          console.log('specialList', _this.specialList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    houseListApi () {
      const _this = this
      houseList(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          _this.houseList = res.result.data
          console.log('houseList', _this.houseList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    houseConditionApi (communityId, buildingId, unitId) {
      const _this = this
      houseCondition({ communityId: communityId, buildingId: buildingId, unitId: unitId }).then((res) => {
        if (res.errorCode === 0) {
          _this.regionCommunity = res.result.regionCommunity
          _this.defaultRegion = res.result.defaultRegion
          _this.queryParam.communityId = res.result.defaultCommunityId
          _this.queryParam.buildingId = res.result.defaultBuildingId
          _this.regionBuilding = res.result.regionBuilding
          _this.regionUnit = res.result.regionUnit
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    houseNameCheck (rule, value, callback) {
      const _this = this
      _this.mdl.houseName = value.trim()
      if (_this.mdl.houseName !== '') {
        _this.checkParams.houseNameStatus = 'success'
        _this.checkParams.houseNameStatusMsg = null
      } else {
        _this.checkParams.houseNameStatus = 'error'
        _this.checkParams.houseNameStatus = '请输入房间号'
        callback(new Error('请输入房间号'))
      }
    },
    telephoneCheck (rule, value, callback) {
      const _this = this
      _this.special.telephone = value.trim()
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(value)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        callback(new Error('手机号格式不正确'))
      }
    },
    realNameCheck (rule, value, callback) {
      const _this = this
      _this.special.realName = value.trim()
      if (_this.special.realName !== '') {
        _this.checkParams.realNameStatus = 'success'
        _this.checkParams.realNameStatusMsg = null
      } else {
        _this.checkParams.realNameStatus = 'error'
        _this.checkParams.realNameStatusMsg = '请输入用户姓名'
        callback(new Error('请输入用户姓名'))
      }
    },
    resetSearchForm () {
      this.defaultRegion = []
      this.queryParam = {
        id: '',
        houseName: '',
        communityId: 0,
        buildingId: 0,
        unitId: 0,
        isBinded: -1,
        areaCode: 0,
        desc: ''
      }
    },
    saveUnitApi () {
      const _this = this
      _this.dataParams.keyId = _this.mdl.keyId ? _this.mdl.keyId : 0
      _this.dataParams.houseName = _this.mdl.houseName
      _this.dataParams.communityId = _this.mdl.communityId
      _this.dataParams.buildingId = _this.mdl.buildingId
      _this.dataParams.unitId = _this.mdl.unitId
      _this.dataParams.areaCode = _this.mdl.areaCode
      _this.dataParams.desc = _this.mdl.desc
      console.log(_this.dataParams)
      if (_this.dataParams.areaCode === '' || _this.dataParams.areaCode === 0 || _this.dataParams.areaCode === '请选择区域') {
        _this.checkParams.areaCodeStatus = 'error'
        _this.checkParams.areaCodeStatusMsg = '请选择区域'
        return false
      } else {
        _this.checkParams.areaCodeStatus = 'success'
        _this.checkParams.areaCodeStatusMsg = null
      }

      if (_this.dataParams.communityId === '' || _this.dataParams.communityId === 0) {
        _this.checkParams.communityIdStatus = 'error'
        _this.checkParams.communityIdStatusMsg = '请选择小区'
        return false
      } else {
        _this.checkParams.communityIdStatus = 'success'
        _this.checkParams.communityIdStatusMsg = null
      }

      if (_this.dataParams.buildingId === '' || _this.dataParams.buildingId === 0) {
        _this.checkParams.buildingIdStatus = 'error'
        _this.checkParams.buildingIdStatusMsg = '请选择楼宇'
        return false
      } else {
        _this.checkParams.buildingIdStatus = 'success'
        _this.checkParams.buildingIdStatusMsg = null
      }

      if (_this.dataParams.unitId === '' || _this.dataParams.unitId === 0) {
        _this.checkParams.unitIdStatus = 'error'
        _this.checkParams.unitIdStatusMsg = '请选择单元'
        return false
      } else {
        _this.checkParams.unitIdStatus = 'success'
        _this.checkParams.unitIdStatusMsg = null
      }

      if (_this.dataParams.houseName === '') {
        _this.checkParams.houseNameStatus = 'error'
        _this.checkParams.houseNameStatusMsg = '请输入房间号'
        return false
      } else {
        _this.checkParams.houseNameStatus = 'success'
        _this.checkParams.houseNameStatusMsg = null
      }

      checkHouse(_this.dataParams).then((resc) => {
        console.log('checkBuilding', resc)
        if (resc.errorCode === 0) {
          saveHouse(_this.dataParams).then((res) => {
            if (res.errorCode === 0) {
              if (_this.dataParams.keyId > 0) {
                _this.$message.info('更新成功')
              } else {
                _this.$message.info('添加成功')
              }
              _this.visible = false
              _this.$refs.table.refresh()
            } else {
              _this.$message.error(res.errorMsg)
            }
          }).catch((err) => {
            console.log(err)
          })
        } else {
          _this.checkParams.houseNameStatus = 'error'
          _this.checkParams.houseNameStatusMsg = resc.errorMsg
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleAdd () {
      this.mdl = {
        keyId: 0,
        id: '',
        houseName: '',
        desc: '',
        communityId: 0,
        buildingId: 0,
        unitId: 0,
        areaCode: 0
      }
      this.resetCheck()
      this.addDefaultRegion = []
      this.regionCommunityAdd = []
      this.regionBuildingAdd = []
      this.regionUnitAdd = []
      this.visible = true
    },
    handleEdit (record) {
      // this.cityListInfoApi(record.provinceId)
      // this.areaListInfoApi(record.cityId)
      this.mdl = Object.assign({}, record)
      this.addDefaultRegion = [record.provinceId, record.cityId, record.areaCode]
      console.log(this.mdl)
      this.regionCommunityApi(record.areaCode, 1)
      this.regionBuildingApi(record.communityId, 1)
      this.regionUnitApi(record.buildingId, 1)
      this.resetCheck()
      this.visible = true
    },
    resetCheck () {
      this.checkParams.areaCodeStatus = 'success'
      this.checkParams.areaCodeStatusMsg = null
      this.checkParams.houseNameStatus = 'success'
      this.checkParams.houseNameStatusMsg = null
      this.checkParams.communityIdStatus = 'success'
      this.checkParams.communityIdStatusMsg = null
      this.checkParams.buildingIdStatus = 'success'
      this.checkParams.buildingIdStatusMsg = null
      this.checkParams.unitIdStatus = 'success'
      this.checkParams.unitIdStatusMsg = null
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    onChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.queryParam.areaCode = e[2] ? e[2] : 0
      this.queryParam.communityId = 0
      if (this.queryParam.areaCode > 0) {
        this.regionCommunityApi(this.queryParam.areaCode, 0)
      } else {
        this.regionCommunity = []
      }
      this.regionBuilding = []
      this.queryParam.buildingId = 0
      this.regionUnit = []
      this.queryParam.unitId = 0
    },
    changeCommunity (e) {
      this.queryParam.communityId = e
      this.regionBuildingApi(this.queryParam.communityId, 0)
      this.queryParam.buildingId = 0
      this.regionUnit = []
      this.queryParam.unitId = 0
    },
    changebuilding (e) {
      this.queryParam.buildingId = e
      this.regionUnitApi(this.queryParam.buildingId, 0)
      this.queryParam.unitId = 0
    },
    regionBuildingApi (communityId, type) {
      const _this = this
      regionBuilding({ communityId: communityId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionBuilding = res.result.data
          } else {
            _this.regionBuildingAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionUnitApi (buildingId, type) {
      const _this = this
      regionUnit({ buildingId: buildingId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionUnit = res.result.data
          } else {
            _this.regionUnitAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      this.mdl.provinceId = e[0] ? e[0] : 0
      this.mdl.cityId = e[1] ? e[1] : 0
      this.mdl.areaCode = e[2] ? e[2] : 0

      if (this.mdl.areaCode === '' || this.mdl.areaCode === 0) {
        this.checkParams.areaCodeStatus = 'error'
        this.checkParams.areaCodeStatusMsg = '请选择区域'
        return false
      } else {
        this.checkParams.areaCodeStatus = 'success'
        this.checkParams.areaCodeStatusMsg = null
      }

      this.regionCommunityApi(this.mdl.areaCode, 1)
      this.mdl.communityId = 0
      this.mdl.buildingId = 0
      this.mdl.unitId = 0
      this.regionBuildingAdd = []
      this.regionUnitAdd = []
    },
    changeAddCommunity (e) {
      console.log(e)
      this.mdl.communityId = e
      if (this.mdl.communityId === '' || this.mdl.communityId === 0) {
        this.checkParams.communityIdStatus = 'error'
        this.checkParams.communityIdStatusMsg = '请选择小区'
      } else {
        this.checkParams.communityIdStatus = 'success'
        this.checkParams.communityIdStatusMsg = null
      }
      this.regionBuildingApi(this.mdl.communityId, 1)
      this.mdl.buildingId = 0
      this.mdl.unitId = 0
      this.regionUnitAdd = []
      console.log(this.mdl)
    },
    changeAddBuilding (e) {
      console.log(e)
      this.mdl.buildingId = e
      console.log('changeAddBuilding', this.mdl.buildingId)
      if (this.mdl.buildingId === '' || this.mdl.buildingId === 0 || this.mdl.buildingId === '0') {
        this.checkParams.buildingIdStatus = 'error'
        this.checkParams.buildingIdStatusMsg = '请选择楼宇'
        console.log('changeAddBuilding', this.mdl.buildingId)
      } else {
        this.checkParams.buildingIdStatus = 'success'
        this.checkParams.buildingIdStatusMsg = null
      }
      this.regionUnitApi(this.mdl.buildingId, 1)
      this.mdl.unitId = 0
    },
    changeAddUnit (e) {
      console.log(e)
      this.mdl.unitId = e
      if (this.mdl.unitId === '' || this.mdl.unitId === 0) {
        this.checkParams.unitIdStatus = 'error'
        this.checkParams.unitIdStatusMsg = '请选择单元'
      } else {
        this.checkParams.unitIdStatus = 'success'
        this.checkParams.unitIdStatusMsg = null
      }
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    addSpecial (record) {
      const specialInfo = Object.assign({}, record)
      this.special.keyId = 0
      this.special.id = ''
      this.special.address = specialInfo.areaCodeName + ' ' + specialInfo.communityName + ' ' + specialInfo.buildingName + ' ' + specialInfo.unitName + ' ' + specialInfo.houseName
      this.special.areaCode = specialInfo.areaCode
      this.special.communityId = specialInfo.communityId
      this.special.buildingId = specialInfo.buildingId
      this.special.unitId = specialInfo.unitId
      this.special.roomId = specialInfo.id
      this.special.telephone = ''
      this.special.email = ''
      this.special.realName = ''
      this.special.desc = ''
      this.special.gender = 0
      this.special.specialIdArr = []
      this.checkParams.telephoneStatus = 'success'
      this.checkParams.telephoneStatusMsg = null
      this.checkParams.realNameStatus = 'success'
      this.checkParams.realNameStatusMsg = null
      this.specialVisible = true
    },
    editSpecial (record) {
      this.special = Object.assign({}, record)
      this.special.address = this.special.areaCodeName + ' ' + this.special.communityName + ' ' + this.special.buildingName + ' ' + this.special.unitName + ' ' + this.special.houseName
      console.log('editSpecial', this.special)
      this.checkParams.telephoneStatus = 'success'
      this.checkParams.telephoneStatusMsg = null
      this.checkParams.realNameStatus = 'success'
      this.checkParams.realNameStatusMsg = null
      this.specialVisible = true
    },
    specialTypeChange (e) {
      console.log('specialTypeChange', e)
      if (e.target.checked) {
        this.special.specialIdArr.push(e.target.value)
      } else {
        var index = this.special.specialIdArr.indexOf(e.target.value)
        if (index > -1) {
          this.special.specialIdArr.splice(index, 1)
        }
      }
    },
    saveResidentApi () {
      const _this = this
      _this.dataParams.keyId = _this.special.keyId ? _this.special.keyId : 0
      _this.dataParams.realName = _this.special.realName
      _this.dataParams.telephone = _this.special.telephone
      _this.dataParams.communityId = _this.special.communityId
      _this.dataParams.buildingId = _this.special.buildingId
      _this.dataParams.unitId = _this.special.unitId
      _this.dataParams.areaCode = _this.special.areaCode
      _this.dataParams.roomId = _this.special.roomId
      _this.dataParams.gender = _this.special.gender
      _this.dataParams.email = _this.special.email
      _this.dataParams.desc = _this.special.desc
      _this.dataParams.specialIdArr = _this.special.specialIdArr
      console.log(_this.dataParams)
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(_this.dataParams.telephone)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        return false
      }

      if (_this.dataParams.realName === '') {
        _this.checkParams.realNameStatus = 'error'
        _this.checkParams.realNameStatusMsg = '请输入住户姓名'
        return false
      } else {
        _this.checkParams.realNameStatus = 'success'
        _this.checkParams.realNameStatusMsg = null
      }

      checkTelephone(_this.dataParams).then((resc) => {
        console.log('checkTelephone', resc)
        if (resc.errorCode === 0) {
          saveResident(_this.dataParams).then((res) => {
            if (res.errorCode === 0) {
              if (_this.dataParams.keyId > 0) {
                _this.$message.success('更新住户成功')
              } else {
                _this.$message.success('添加住户成功')
              }
              _this.specialVisible = false
              _this.$refs.table.refresh()
            } else {
              _this.$message.error(res.errorMsg)
            }
          }).catch((err) => {
            console.log(err)
          })
        } else {
          _this.checkParams.telephoneStatus = 'error'
          _this.checkParams.telephoneStatusMsg = resc.errorMsg
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    delResidentApi (id, roomId) {
      const _this = this
      delResident({ keyId: id, roomId: roomId }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    gotoResident (roomId) {
      this.$router.push({ path: '/resident/resident-list?roomId=' + roomId })
    }
  }
}
</script>
